var bpt = (function () {
    'use strict';

    // Global Variables
    var $ = jQuery.noConflict(),
        loadElement,
        $transition,
        historyChanged = false,
        $body = $('body'),
        $bodyID = $body.attr('id'),
        breakpoint = 737,
        $window = $(window),
        $windowWidth = $window.width(),
        open = false;

    // Prevent Keyboard Trap Variables
    var activeElement, focusableEls, previousFocusedEl, lastFocusableEl, firstFocusableEl;

    return {
        init: function () {
            console.log('Designed and developed by Baxter & Bailey - https://baxterandbailey.co.uk');

            bpt.home.homeInit();
            bpt.programme.programmeInit();
            bpt.utilities.utilitiesInit();
            bpt.menu();
        },

        menu: function () {
            $('.nav-icon')
                .off('click')
                .on('click', function (event) {
                    $(document.body).toggleClass('nav-active');
                    event.preventDefault();

                    open = !open;
                    bpt.utilities.setFocus();
                    bpt.utilities.keyboardTabbing();
                });

            $(document).keydown(function (e) {
                if (e.which === 27) {
                    $(document.body).removeClass('nav-active');
                    open = false;
                }
            });
        },

        home: {
            homeInit: function () {
                if ($bodyID !== 'home') return;

                bpt.home.swiper();
            },

            swiper: function () {
                var swiper = new Swiper('.swiper-container', {
                    keyboardControl: true,
                    pagination: '.swiper-pagination',
                    paginationType: 'bullets',
                    paginationClickable: true,
                    speed: 1000,
                    autoplay: 5000,
                    loop: true,

                    onInit: function () {
                        setTimeout(function () {
                            $('.swiper-slide-active').addClass('reveal-standfirst');
                        }, 1250);
                        setTimeout(function () {
                            $('#id-full').css({ opacity: '0' });
                            $('#id-leap').css({ opacity: '1' });
                        }, 5000);
                    },

                    onSlideChangeStart: function () {
                        setTimeout(function () {
                            $('.swiper-slide-active').removeClass('reveal-text');
                            $('.swiper-slide-active').removeClass('reveal-standfirst');
                        });
                    },

                    onSlideChangeEnd: function (swiper) {
                        let logoChoice = $('.swiper-slide-active').data('logo');
                        console.log('#id-' + logoChoice);

                        $('svg.identity').css({ opacity: '0' });
                        $('#id-' + logoChoice).css({ opacity: '1' });

                        /* if (swiper.realIndex === 0) {
                            $('#id-soar').css({ opacity: '0' });
                            $('#id-full').css({ opacity: '0' });
                            $('#id-lets-go').css({ opacity: '0' });
                            $('#id-leap').css({ opacity: '1' });
                        } else if (swiper.realIndex === 1) {
                            $('#id-soar').css({ opacity: '1' });
                            $('#id-lets-go').css({ opacity: '0' });
                            $('#id-leap').css({ opacity: '0' });
                        } else if (swiper.realIndex === 2) {
                            $('#id-leap').css({ opacity: '0' });
                            $('#id-soar').css({ opacity: '0' });
                            $('#id-lets-go').css({ opacity: '1' });
                        } */

                        setTimeout(function () {
                            $('.swiper-slide-active').addClass('reveal-text');
                        }, 250);

                        setTimeout(function () {
                            $('.swiper-slide-active').addClass('reveal-standfirst');
                        }, 1250);
                    }
                });
            }
        },

        programme: {
            programmeInit: function () {
                if ($bodyID !== 'programme') return;

                bpt.programme.programmeScrolling();
            },

            programmeScrolling: function () {
                var $sections = $('#workshops a');

                if ($windowWidth > breakpoint) {
                    bpt.utilities.pageScrolling($sections, -45);
                } else {
                    bpt.utilities.pageScrolling($sections, -80);
                }
            }
        },

        utilities: {
            utilitiesInit: function () {
                bpt.utilities.nonStick();
            },

            setFocus: function () {
                activeElement = document.querySelector('.menu');
                focusableEls = activeElement.querySelectorAll(
                    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
                );
                firstFocusableEl = focusableEls[0];
                lastFocusableEl = focusableEls[focusableEls.length - 1];

                firstFocusableEl.focus();
            },

            keyboardTabbing: function () {
                var KEY_TAB = 9;

                function handleBackwardTab() {
                    $(document).keydown(function (e) {
                        if (document.activeElement === firstFocusableEl) {
                            e.preventDefault();
                            lastFocusableEl.focus();
                        }
                    });
                }
                function handleForwardTab() {
                    $(document).keydown(function (e) {
                        if (document.activeElement === lastFocusableEl) {
                            e.preventDefault();
                            firstFocusableEl.focus();
                        }
                    });
                }

                $(document).keydown(function (e) {
                    switch (e.keyCode) {
                        case KEY_TAB:
                            if (focusableEls.length === 1) {
                                e.preventDefault();
                                break;
                            }

                            if (e.which === 16) {
                                handleBackwardTab();
                            } else {
                                handleForwardTab();
                            }

                            break;
                        default:
                            break;
                    }
                });
            },

            nonStick: function () {
                var touch =
                    'ontouchstart' in document.documentElement ||
                    navigator.MaxTouchPoints > 0 ||
                    navigator.msMaxTouchPoints > 0;

                if (touch) {
                    // remove all :hover stylesheets
                    try {
                        // prevent exception on browsers not supporting DOM styleSheets properly
                        for (var si in document.styleSheets) {
                            var styleSheet = document.styleSheets[si];
                            if (!styleSheet.rules) continue;

                            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                                if (!styleSheet.rules[ri].selectorText) continue;

                                if (styleSheet.rules[ri].selectorText.match(':hover')) {
                                    styleSheet.deleteRule(ri);
                                }
                            }
                        }
                    } catch (ex) {}
                }
            },

            pageScrolling: function ($el, $offsetValue) {
                $el.on('click', function (e) {
                    if ($(this).text()) {
                        e.preventDefault();

                        var target = $(this.hash),
                            $this = $(this);

                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            setTimeout(function () {
                                $('html, body').animate(
                                    {
                                        scrollTop: target.offset().top + $offsetValue
                                    },
                                    1500,
                                    function () {
                                        return false;

                                        var $target = $(target);
                                        $target.focus();

                                        if ($target.is(':focus')) {
                                            return false;
                                        } else {
                                            $target.attr('tabindex', '-1');
                                            $target.focus();
                                        }
                                    }
                                );
                            }, 250);
                        }
                    }
                });
            }
        }
    };
})();

jQuery(document).ready(bpt.init);
